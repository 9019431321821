import Polyglot from 'node-polyglot';
import defaults from '../../config/locales/defaults/de-mediadaten';
import fallbacks from '../../config/locales/zimpel/de';
import current from '../../config/locales/mediadaten/de';

const polyglot = new Polyglot({ phrases: defaults['de-DE-mediadaten'] });
polyglot.extend(fallbacks['de-DE-zimpel']);
polyglot.extend(current['de-DE-mediadaten']);

window.polyglot = polyglot;
